<template>
  <div class="modal">
    <button class="modal-close">
      <div class="cross"></div>
    </button>
    <div class="modal-left">
      <div class="offer"></div>
    </div>
    <div class="modal-right">
      <h1 class="title">Регистрация</h1>
      <form
        action="#"
        id="registrationForm"
        :class="{'form_disabled': isRequestSending}"
        @submit.prevent="submitForm"
      >
        <div class="form-row">
          <select
            id="telCode"
            name="telCode"
            v-model="telCode"
          >
            <option value="+7" selected>+7</option>
            <option value="+380">+380</option>
          </select>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Номер телефона"
            v-model="phone"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            id="password"
            placeholder="Введите пароль"
            v-model="password"
            required
          />
        </div>
        <div class="form-row">
          <select
            id="country"
            v-model="country"
          >
            <option value="RU" selected>Россия</option>
            <option value="UA">Украина</option>
          </select>
          <select
            id="currency"
            v-model="currency"
          >
            <option value="RUB" selected>RUB</option>
            <option value="USD">USD</option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="text"
            id="promocode"
            v-model="promocode"
          />
        </div>
        <div class="checkbox-group">
          <input
            type="checkbox"
            id="checkbox"
            name="checkbox"
            v-model="checkbox"
            required
          />
          <label for="checkbox">
            Я согласен с <a href="#">Условиями и приложениями</a> и
            подтверждаю, что мне исполнилось 18 лет
          </label>
        </div>
        <input
          type="submit"
          class="btn-register"
          id="register"
          value="Регистрация"
        />
      </form>
      <div class="sign-in">
        <p>Есть аккаунт?</p>
        <p>Вход <img src="@/assets/img/arrow-right-solid.png" alt="icon"><span></span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
